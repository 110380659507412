import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  DatatableComponent,
  SelectionType,
  SortPropDir,
  TableColumn,
} from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { IconSizeEnum } from '@app/shared/models/icon-size-enum';
import { TooltipPlacementEnum } from '@app/shared/models/tooltip-placement-enum';
import { defaultLimit } from '@app/shared/helpers/pagination-helpers';
import { FooterItem } from '../dashboard-table/models/footer-item';
import { SearchRequest } from '../search/interfaces/search-request';
import { DatatableRowStyler } from './styler/datatable-row-styler';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges, OnDestroy, OnInit {
  iconSize = IconSizeEnum.Medium;

  @ViewChild(DatatableComponent)
  datatable: DatatableComponent;

  @ViewChild('headerTemplate', { static: true })
  headerTemplate: TemplateRef<any>;

  @ViewChild('dateCellTemplate', { static: true })
  dateCellTemplate: TemplateRef<any>;

  @ViewChild('dateTimeCellTemplate', { static: true })
  dateTimeCellTemplate: TemplateRef<any>;

  @ViewChild('expansionCellTemplate', { static: true })
  expansionCellTemplate: TemplateRef<any>;

  @ViewChild('copyCellTemplate', { static: true })
  copyCellTemplate: TemplateRef<any>;

  @Input()
  isLoading: boolean;

  @Input()
  stretchColumns = true;

  @Input()
  horizontalScrollbar = false;

  @Input()
  verticalScrollbar = true;

  @Input()
  rowStyler: DatatableRowStyler;

  _rows: any[] = [];

  @Input()
  set rows(newRows: any[]) {
    this._rows = newRows;
    // Recommended way of updating rows to trigger change detection
    // https://swimlane.gitbook.io/ngx-datatable/readme/cd
    if (Array.isArray(this._rows)) {
      this._rows = [...this._rows];
    }
    this.forceTableRerender();
  }

  @Input()
  set clearTableSelection(value: boolean) {
    if (value && this.selectedItem) {
      this.selectedItem = [];
    }
  }

  _columns: TableColumn[] = [];
  @Input()
  set columns(tableColumns: TableColumn[]) {
    const indexDecrement =
      tableColumns.findIndex(
        (column) =>
          column.prop.toString().toLowerCase().includes('action') ||
          column.prop.toString().toLowerCase().includes('expand'),
      ) === 0
        ? 1
        : 0;

    this._columns = tableColumns.map((column, index) => ({
      ...column,
      cellTemplate: this.resolveCellTemplate(column, index - indexDecrement),
      headerTemplate: this.resolveHeaderTemplate(),
    }));
  }

  @Input()
  totalPages = 0;

  @Input()
  totalRows = 0;

  @Input()
  limit = defaultLimit;

  @Input()
  rowHeight = 40;

  @Input()
  headerHeight = 40;

  @Input()
  selectedItem: any[] = [];

  @Input()
  shrinkHeight = false;

  @Input()
  externalSort = true;

  @Input()
  showFooter: boolean;

  @Input()
  footerItems: FooterItem[] = [];

  @Input()
  set filteredItems(search: SearchRequest) {
    this._columns = this._columns.map((column) => ({
      ...column,
      filtered:
        search && search.filterParameters
          ? search.filterParameters
              .map((param) => param.columnName)
              .includes(column.prop.toString())
          : false,
    }));
  }

  @Input()
  nonClickableColumns: string[] = [];

  @Input()
  nonStyledColumns: string[] = [];

  @Input()
  allowExpandingRows = false;

  @Input()
  expandedRowTemplate: TemplateRef<any>;

  @Input()
  rowDetailHeight = 100;

  @Input()
  expandRowDetails$: Subject<any> = new Subject();

  @Input()
  expandedRow: any;

  @Input()
  selectionType = SelectionType.single;

  @Output()
  loadMore = new EventEmitter<{ limit: number; offset: number }>();

  @Output()
  selected = new EventEmitter<string>();

  @Output()
  sort = new EventEmitter<SortPropDir>();

  @Output()
  requestRowExpand = new EventEmitter<any>();

  @Input()
  hasSummaryRow = false;

  @Input()
  summaryRowHeight = 40;

  @ViewChild('tableOutlet', { read: ViewContainerRef })
  tableOutlet: ViewContainerRef;
  @ViewChild('tableContent', { read: TemplateRef })
  tableContent: TemplateRef<any>;

  public rerender() {
    this.tableOutlet?.clear();
    this.tableOutlet?.createEmbeddedView(this.tableContent);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.rerender();
  }

  alive = true;

  placement = TooltipPlacementEnum.Right;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.expandRowDetails$
      .pipe(takeWhile(() => this.alive))
      .subscribe((row) => {
        if (row) {
          this.expandRow(row);
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem) {
      this.selectedItem = this.selectedItem ? this.selectedItem : [];
      this.rerender();
    }
  }

  resolveHeaderTemplate() {
    return this.headerTemplate;
  }

  resolveCellTemplate(column: TableColumn | any, columnIndex: number) {
    const isColumnStyleDisabled = this.nonStyledColumns.includes(column.prop);
    if (isColumnStyleDisabled) {
      return null;
    }

    if (
      column.prop.toLowerCase().includes('date') &&
      !column.cellTemplate &&
      !column.pipe
    ) {
      return this.dateCellTemplate;
    }

    if (
      column.prop.toLowerCase().includes('time') &&
      !column.cellTemplate &&
      !column.pipe
    ) {
      return this.dateTimeCellTemplate;
    }

    if (column.prop.toLowerCase().includes('expand') && !column.cellTemplate) {
      return this.expansionCellTemplate;
    }

    if (
      !column.cellTemplate &&
      (column.canCopy || (columnIndex === 0 && column.canCopy !== false))
    ) {
      return this.copyCellTemplate;
    }

    return column.cellTemplate;
  }

  onScroll(offsetY: number) {
    // total height of all rows in the viewport
    const viewHeight =
      this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    // check if we scrolled to the end of the viewport
    if (
      !this.isLoading &&
      offsetY + viewHeight >= this._rows.length * this.rowHeight
    ) {
      // total number of results to load
      let limit = this.limit;

      // check if we haven't fetched any results yet
      if (this._rows.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limit = Math.max(pageSize, this.limit);
      }

      const offset = this._rows.length;
      if (Math.ceil(offset / limit) < this.totalPages) {
        this.loadMore.emit({ limit, offset: this._rows.length });
      }
    }
  }

  activated(event: any) {
    if (event.type === 'click') {
      const isColumnClickDisabled = this.nonClickableColumns.includes(
        event.column.prop,
      );
      if (isColumnClickDisabled) {
        return;
      }

      event.event.target.parentElement.blur();
      this.selected.emit(event.row);
    }
  }

  onSort(event: any) {
    this.sort.emit(event.sorts[0]);
  }

  forceTableRerender() {
    // Sometimes the table doesn't render correctly when rows/columns
    // are being updated and the [scrollbarV] is used.
    // One of the work arounds is to scroll slightly and the table
    // rerenders correctly.

    // Sauce:
    // https://github.com/swimlane/ngx-datatable/issues/1220#issuecomment-478276335
    // https://github.com/swimlane/ngx-datatable/issues/985
    // https://github.com/swimlane/ngx-datatable/issues/843#issuecomment-351274990

    setTimeout(() => {
      this.datatable.bodyComponent.offsetY = 0;
    }, 0);
  }

  applyRowHighlighting = (row: any) => {
    if (this.rowStyler) {
      return this.rowStyler.applyRowStyling(row);
    }

    return {};
  };

  transform(item: FooterItem) {
    if (item.pipe) {
      return item.pipe.transform(item.value);
    }

    return item.value;
  }

  expandRow(row: any) {
    if (this.datatable && this.allowExpandingRows) {
      // We want this to run via the event loop to avoid render issues
      setTimeout(() => {
        this.datatable.rowDetail.collapseAllRows();
        this.datatable.rowDetail.toggleExpandRow(row);
      });
    }
  }

  requestOpenRow(row: any) {
    this.requestRowExpand.emit(row);
  }

  closeRow(row: any) {
    this.datatable.rowDetail.toggleExpandRow(row);
    this.expandedRow = null;
  }
}

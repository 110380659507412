import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { AppConfigService } from './app-config/app-config.service';

@Injectable()
export class AppInsightsService {
  private appInsights = new ApplicationInsights({
    config: {
      ...this.appConfig.config.applicationInsights,
      enableAutoRouteTracking: true,
    },
  });

  constructor(private appConfig: AppConfigService) {
    this.appInsights.loadAppInsights();
  }

  logTrace(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Verbose,
    });
  }

  logInformation(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Information,
    });
  }

  log(message: string, data: any): void {
    this.appInsights.trackTrace(
      {
        message,
        severityLevel: SeverityLevel.Information,
      },
      data,
    );
  }

  logWarning(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Warning,
    });
  }

  logException(exception: any) {
    this.appInsights.trackException({ exception });
  }

  logError(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Error,
    });
  }

  logCritical(message: string): void {
    this.appInsights.trackTrace({
      message,
      severityLevel: SeverityLevel.Critical,
    });
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }
}

export interface GlPeriod {
  month: number;
  year: number;
}

export const getDefaultGlPeriod = (): GlPeriod => ({
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
});

export const parseValueToGlPeriod = (value: string): GlPeriod => {
  if (!value) {
    return getDefaultGlPeriod();
  }
  return {
    year: parseInt(value.toString().slice(0, 4), 10),
    month: parseInt(value.toString().slice(4, 6), 10),
  };
};

export const parseGlPeriodToNumber = (value?: GlPeriod): number => {
  if (!value) {
    value = getDefaultGlPeriod();
  }
  return value.year * 100 + value.month;
};

export const parseDateValueToGlPeriod = (value: Date): GlPeriod => {
  if (!value) {
    return getDefaultGlPeriod();
  }
  return {
    year: value.getFullYear(),
    month: value.getMonth() + 1,
  };
};

<div>
  <ng-container #tableOutlet [ngTemplateOutlet]="tableContent"></ng-container>
</div>
<ng-template #tableContent>
  <ngx-datatable
    #datatable
    class="material scrollable-height"
    [class.short-table]="shrinkHeight && verticalScrollbar"
    [class.tall-table]="!shrinkHeight && verticalScrollbar"
    [columnMode]="stretchColumns ? 'force' : 'standard'"
    [headerHeight]="headerHeight"
    [rows]="_rows"
    [scrollbarV]="verticalScrollbar"
    [scrollbarH]="horizontalScrollbar"
    [footerHeight]="showFooter ? 100 : 0"
    [selected]="selectedItem"
    [columns]="_columns"
    [rowHeight]="rowHeight"
    [rowClass]="applyRowHighlighting"
    [externalSorting]="externalSort"
    (sort)="onSort($event)"
    (activate)="activated($event)"
    (scroll)="onScroll($event.offsetY)"
    [loadingIndicator]="isLoading"
    [selectionType]="selectionType"
    [summaryRow]="hasSummaryRow"
    [summaryPosition]="'bottom'"
    [summaryHeight]="summaryRowHeight"
  >
    <ngx-datatable-row-detail
      [rowHeight]="rowDetailHeight"
      [template]="expandedRowTemplate"
    >
    </ngx-datatable-row-detail>
    <ngx-datatable-footer *ngIf="showFooter && footerItems">
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <div>
          <div class="content">
            <div *ngFor="let item of footerItems" class="column">
              <app-text-display
                [labelName]="item.label"
                [value]="transform(item)"
              ></app-text-display>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</ng-template>

<ng-template
  #headerTemplate
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
>
  <span [class]="column.sortable ? 'pointer' : ''">
    <span class="datatable-header-cell-label" (click)="sort()"
    >{{ column.name }}
    </span>

    <!-- Commented out for now, Custom sort direction arrows if needed -->
    <!-- <span class="fem-icon" [class.fem-icon-arrow-up-circle]="sortDir === 'asc'" [class.fem-icon-arrow-down-circle]="sortDir === 'desc'"></span> -->

    <span *ngIf="column.filtered" class="fem-icon fem-icon-filter"></span>
  </span>
</ng-template>

<!-- Cell Templates -->
<ng-template #dateCellTemplate let-row="row" let-value="value" let-i="index">
  <span class="standard-date">{{ value | standardDate }}</span>
</ng-template>

<ng-template
  #dateTimeCellTemplate
  let-row="row"
  let-value="value"
  let-i="index"
>
  <span class="standard-date">{{ value | standardDateTime }}</span>
</ng-template>

<ng-template #expansionCellTemplate let-row="row" let-value="value">
  <div>
    <span (click)="row === expandedRow ? closeRow(row) : requestOpenRow(row)">
      <app-icon [name]="'caret-right'" *ngIf="row !== expandedRow"></app-icon>
      <app-icon [name]="'caret-down'" *ngIf="row === expandedRow"></app-icon>
    </span>
  </div>
</ng-template>
<ng-template
  #copyCellTemplate
  let-row="row"
  let-value="value"
  let-column="column"
>
  <span
  >{{ value }}
    <app-copy-button
      *ngIf="value"
      [copyItem]="value"
      [label]="column.name"
      [iconSize]="iconSize"
      [placement]="placement"
    ></app-copy-button
    ></span>
</ng-template>
